import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import { HashRouter } from 'react-router-dom/cjs/react-router-dom.min';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Pages
const Form = React.lazy(() => import('./pages/forms/FormDetails'));
const Page404 = React.lazy(() => import('./pages/page404/Page404'));

class App extends Component {
  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading}> {/* Specify a fallback component */}
          <Switch>
            <Route exact path="/:department/:form/:id" name="Form Details" render={props => <Form {...props} />} />
            <Route path="*" name="Page 404" render={props => <Page404 {...props} />} />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
